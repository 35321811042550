import React from "react";
import '../styles/main.css'
import '../styles/Service.css'
import { IoAnalyticsOutline, IoBarChartSharp, IoCheckmarkCircle    } from "react-icons/io5";
const ServiceSection = () =>{
    return(
        <section className="flex" id="services">
        <div id="content" className="flex-column">
        <div id="content-divider">
                <span></span>
            </div>
            <h1 className="no-margin">Services</h1>
            <p>By utilizing our market acumen and long-standing relationships in the financial industry, we work with clients to determine the key factors necessary to meet their specific goals.</p>
            <div className="flex" id="services-deck">
                <div className="icon-card flex">
                    <div id="card-content" className="flex-column text-center">
                       
                    <h4>Bridge Loans</h4>
                        <hr/>
                        <h4>Factoring</h4>
                      
                    </div>
                </div>
                <div className="icon-card flex">
                    <div id="card-content" className="flex-column text-center align">
                       
                        
                    <h4>Specialty Finance</h4>
                    <hr/>
                    <h4>Private Placements</h4>
                       
                    </div>
                </div>
                <div className="icon-card flex">
                    <div id="card-content" className="flex-column text-center">
                    
                   
                    <h4>Asset-backed Lending</h4>
                        <hr/>
                    <h4>Equity</h4>
                    </div>
                </div>
                <div className="icon-card flex">
                    <div id="card-content" className="flex-column text-center">
                        <h4>Family Offices</h4>
                        <hr/>
                        <h4>PO Financing</h4>
                    </div>
                </div>
            

            </div>
            
            <hr/>
            <ul className="flex" id="icon-section">
                <li id="icon-words" className="flex-column align-center">
                <IoAnalyticsOutline id="icon" size={100}/>
                    <h3>Evaluate</h3>
                </li>
                <li id="icon-words" className="flex-column align-center">
                    <IoBarChartSharp id="icon" size={100}/>
                    <h3>Optimize</h3></li>
                <li id="icon-words" className="flex-column align-center">
                <IoCheckmarkCircle id="icon" size={100}/>
                    <h3>Execute</h3>
                    </li>
            </ul>
            
            
           
        </div>
   
    </section>
    )
}

export default ServiceSection;